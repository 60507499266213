import React from 'react';
import logo from '../assets/logo.svg';

import { t } from '../lang';
const PrivacyPolicy = () => {
  return (
    <>
      <header style={{ textAlign: 'center', padding: 30 }}>
        <a href="/"><img src={logo} alt="road24" /></a>
      </header>
      <div className="privacy" style={{ padding: '20px 60px 40px' }}>
        <h2 style={{ textAlign: 'center', fontWeight: 'bold' }}><strong>Ommaviy Oferta</strong></h2>
        <p style={{ textAlign: 'center' }}>(Road 24 platformasi uchun)</p>
        <p style={{ display: 'flex', justifyContent: 'space-between' }}><strong>Toshkent   sh.                                                                              </strong><strong>                                       </strong><strong> </strong><strong> </strong><strong>01.01.2024</strong></p>
        <p></p>
        <p style={{ textAlign: 'justify', textIndent: '45px' }}>	Ushbu Oferta cheksiz miqdordagi yuridik va jismoniy shaxslarga, “KASH APP” MCHJ (keyingi o’rinlarda Ma’muriyat yoki Operator deb yuritiladi) tomonidan “«Road24»” platformasidan foydalanish bo’yicha xizmat ko’rsatish to’g’risidagi shartnoma tuzishga qaratilgan rasmiy ommaviy oferta hisoblanadi. taqdim etish bo’yicha xizmatlar ko’rsatish.</p>
        <p style={{ textAlign: 'justify', textIndent: '45px' }}>	Ushbu Ofertada ko’rsatilgan harakatlarning bajarilishi – platformada ro’yhatdan o’tadigan shaxslar tomonidan ushbu Ofertada (keyingi o’rinlarda Shartnoma deb yuritiladi) belgilangan shartlarda, tartibda va hajmda xizmatlar ko’rsatish shartnomasini tuzishga roziligini (qabul qilishini) so’zsiz va shartiz tasdiqlashdir.</p>
        <p></p>
        <p><strong>1. ASOSIY TUSHUNCHALAR</strong></p>
        <p>1.1. Ushbu Shartnomada quyidagi asosiy tushunchalardan foydalaniladi:</p>
        <p style={{ textAlign: 'justify', textIndent: '45px' }}><strong>	</strong><strong>“</strong><strong>KASH</strong><strong> </strong><strong>APP</strong><strong>”</strong><strong> MChJ</strong> tomonidan taqdim etilgan ma’muriyat (sayt ma’muriyati yoki operatori) «Road24» platformasini ishlab chiqaruvchisi hisoblanadi. </p>
        <p style={{ textAlign: 'justify', textIndent: '45px' }}><strong>	</strong><strong>«Road24»</strong><strong> platformasi (veb-sayt (https://</strong><strong>Road24</strong><strong>.uz) va ilova yoki matnda Xizmat</strong><strong>, Sayt</strong><strong>)</strong> foydalanuvchilarga bir qator innovatsion va boshqa xizmatlarni olish imkonini beruvchi onlayn platforma (kompyuter uchun) koʻrinishidagi dasturdir. Platforma rasmiy ro’yxatdan o’tgan mualliflik huquqi obyekti hisoblanadi.</p>
          <p><strong>Foydalanuvchi </strong>– Platforma va uning funksiyalaridan foydalanishni boshlagan, xizmatlardan foydalanayotgan va haq to’layotgan, Platformada o’z shaxsiy kabinetiga ega bo’lgan jismoniy yoki yuridik shaxs;</p>
          <p><strong>Hamkor tashkilotlar -</strong> banklar, sug’urta tashkilotlari, reklama beruvchilar va o’z xizmatlarini «Road24» platformasi orqali taqdim etiladigan boshqa tashkilotlar;</p>
          <p><strong>Xizmatlar (xizmat) - </strong>«Road24» platformasi orqali Foydalanuvchilarga ko’rsatiladigan vositachilik, axborot va maslahat xizmatlari;</p>
          <p>1.2. Ushbu Shartnoma 1.1-bandda ko’rsatilmagan shartlardan ham foydalanishi mumkin. Bunday atamalarning ma’nosi ushbu fuqarolik va boshqa qonun hujjatlariga hamda Shartnoma matniga muvofiq tushunilishi kerak.</p>
          <p></p>
          <p><strong>2. QABUL QILISH TARTIBI</strong></p>
          <p>2.1. Ushbu Ommaviy ofertani qabul qilish Foydalanuvchining «Road24» platformasida roʻyxatdan oʻtganligini tasdiqlash orqali amalga oshiriladi. Platforma IOS, Android platformalarida va www.»Road24».uz saytida ishlaydi. Shartnoma taklif qabul qilingan paytdan boshlab tuzilgan hisoblanadi.</p>
          <p>2.2. Agar Foydalanuvchi ushbu Shartnoma shartlariga rozi bo’lmasa, u Saytdan (Platforma) foydalanishni to’xtatishi kerak.</p>
          <p>2.3. Agar Foydalanuvchi ushbu taklifni buzsa, Sayt ma’muriyati bir tomonlama tartibda ushbu Shartnomani to’liq yoki qisman bekor qilishi yoki cheklovlar qo’yishi mumkin.</p>
          <p>2.4. Platforma tomonidan ko’rsatiladigan xizmatlarning to’xtatilishi Ma’muriyat va Foydalanuvchi o’rtasidagi shartnomaning bekor qilinishini anglatadi.</p>
          <p>2.5. Oferta hududi O’zbekiston Respublikasi (keyingi o’rinlarda O’z. Res. deb yuritiladi) hisoblanadi.</p>
          <p></p>
        <p><strong>3. SHARTNOMA MAQSADI</strong></p>
          <p>3.1. Ushbu Shartnomaning predmeti – Platformada Foydalanuvchilarni ro’yxatdan o’tkazish orqali mijozlar (foydalanuvchilar) tomonidan xizmatlar olish sohasida tomonlarning o’zaro manfaatli hamkorligi hisoblanadi. Shu jumladan avtotransport vositalari oynalarining tusini o’zgartirish (qoraytirish) uchun ruxsatnomalar, sug’urta polisi va boshqalar. </p>
          <p>3.1.1. Qonunchilik hujjatlarida belgilangan tartibda avtotransport vositalari oynalarining tusini o’zgartirish (qoraytirish) uchun ruxsatnomalar, sug’urta polisi olish bo’yicha hujjatlar olish imkoniyati sinov jarayonida amalga oshiriladi.</p>
          <p>3.2. Ushbu Shartnomaga muvofiq, Foydalanuvchi Platformaning pullik xizmatlaridan foydalanadi, Foydalanish shartlariga rioya qiladi va belgilangan tartibda ko’rsatilgan pullik xizmatlar uchun vositachilik haqini to’lash majburiyatini oladi. Maʼmuriyat Platformaning uzluksiz ishlashini taʼminlaydi, Foydalanuvchilarga Platforma xizmatlari va imkoniyatlaridan pullik foydalanishni taʼminlaydi va belgilangan tartibda toʻlovlarni oladi. Shu bilan birga, Operator Platformaning funksionalligida nazarda tutilgan, O’zbekiston Respublikasi qonunchiligida taqiqlanmagan xizmatlar va boshqa operatsiyalarni amalga oshirishda faqat texnik va axborot o’zaro hamkorligini ta’minlaydi hamda hamkor tashkilotlar foydasiga mijozlarning joriy hisob varaqlaridan (kartalar) tegishli mablag’larni yechish va hamkor tashkilotlarga o’tkazishni o’z zimmasiga olmaydi.</p>
          <p>3.3. Shartnomada to’g’ridan-to’g’ri nazarda tutilmagan barcha hollarda tomonlar O’zbekiston Respublikasining amaldagi qonunchiligiga va tadbirkorlik ish yuritish odatlariga rioya qilishlari shart. Ushbu Shartnomaning foydalanuvchi tomonidan qabul qilinishi quyidagi hujjatlarni ham qabul qilinganligini anglatadi:</p>
          <p>- “Road24” foydalanuvchilarining shaxsiy maʼlumotlari uchun maxfiylik siyosati” (https://»Road24».uz/privacy-policy/ manzilida joylashgan). Ushbu hujjat ushbu Bitimning ajralmas qismi hisoblanadi.</p>
          <p>3.4. Agar hujjatlarning yangi tahrirlarida boshqacha tartib nazarda tutilgan bo’lmasa, hujjatlarning yangi tahrirlari ular e’lon qilingan kundan boshlab kuchga kiradi. Foydalanuvchi Shartnomaga o’zgartirish va qo’shimchalar kiritganidan keyin ham platforma xizmatlaridan foydalanishda davom etayotgani o’zgartirish va qo’shimchalar u tomonidan qabul qilinganligini bildiradi.</p>
          <p>3.5. <strong>Platforma xizmatlaridan foydalanishdan oldin Foydalanuvchi ushbu taklif bilan toʻliq tanishishi va tarif rejasida koʻrsatilgan narxlar bilan tanishishi kerak</strong>. Ushbu Ommaviy ofertani qabul qilish Foydalanuvchini Platformada roʻyxatdan oʻtkazish yoʻli bilan amalga oshiriladi va <strong>Oʻzbekiston Respublikasi Fuqarolik kodeksining 357, 364, 365, 366, 367, 369 va 370-moddalariga muvofiq Ofertaga toʻliq va soʻzsiz rioya etilishini bildiradi. .</strong></p>
          <p>3.6. Ushbu Shartnoma ochiq va hamma uchun umumma’lum hujjatdir.</p>
          <p>3.7. Ushbu Shartnomani tuzish orqali Foydalanuvchi O’zbekiston Respublikasining shaxsiy ma’lumotlar to’g’risidagi qonunchiligi talablariga muvofiq Operatorga uning shaxsiy ma’lumotlarini qayta ishlashga to’liq, cheksiz roziligini taqdim etadi. Operatorning Internet-resursida joylashtirilgan shaxsiy ma’lumotlarni himoya qilish va qayta ishlash huquqini beradi.</p>
          <p>3.8. Ushbu taklif O’zbekiston Respublikasining Fuqarolik kodeksi, O’zbekiston Respublikasining “Elektron tijorat to’g’risida”, “Elektron hujjat aylanishi to’g’risida”gi qonunlari va boshqalar asosida ishlab chiqilgan.</p>
          <p></p>
        <p><strong>4. PLATFORMA STATUS</strong><strong>I</strong></p>
          <p>4.1. Platforma – https://»Road24».uz manzilida Internet tarmog’ida bunday ma’lumotlarni taqdim etuvchi kompyuter dasturlari va ma’lumotlar to’plamini aks ettiradigan internet-resurs (axborot vositachisi, veb-sayt, xizmat).</p>
          <p>4.2. https://»Road24».uz (domen nomi) tarmoq manzili va undan foydalanish bilan bog’liq barcha huquqlar platforma ma’muriyatiga tegishli. Manfaatdor shaxslarning Saytga kirishi O’zbekiston Respublikasining amaldagi qonunchiligiga muvofiq ushbu Shartnoma bilan belgilanadi.</p>
          <p>4.3. Intellektual faoliyat natijalaridan foydalanish huquqi Ma’muriyatga tegishli.</p>
          <p>4.4. Jismoniy va yuridik shaxslarning ushbu Shartnoma boʻyicha yoki Sayt faoliyati bilan bogʻliq ariza, taklif va shikoyatlari, shuningdek, vakolatli organlarning soʻrovlari Maʼmuriyatning elektron pochta manziliga yoki support@»Road24».uz elektron pochta manziliga yoki foydalanuvchilarni qo’llab-quvvatlash xizmatidagi raqamlar orqali qabul qilinadi.</p>
          <p>4.5. Ushbu Shartnoma hech qanday tarzda Foydalanuvchiga kompaniya nomi, savdo belgisi, domen nomi va Sayt ma’muriyatining boshqa taniqli belgilaridan foydalanish huquqini bermaydi. Sayt ma’muriyatining kompaniya nomi, tovar belgisi, domen nomi va boshqa taniqli belgilaridan foydalanish huquqi Sayt ma’muriyatining yozma roziligi bilan berilishi mumkin.</p>
          <p>4.6. Saytni boshqarish va rivojlantirish O’zbekiston Respublikasi qonunchiligiga muvofiq Ma’muriyat tomonidan amalga oshiriladi.</p>
          <p>4.7. Platforma ma’muriyati foydalanuvchilarning takliflarini inobatga olgan holda qo’shimcha xizmatlar ko’rsatishi, platformaning imkoniyatlarini yanada kengaytirishi mumkin.</p>
          <p> </p>
        <p><strong>5. TOMONLARNING HUQUQ VA MAJBURIYAT</strong><strong>LARI</strong></p>
          <p>5.1. Ma’muriyat va Foydalanuvchi(lar) ushbu Shartnoma taraflari hisoblanadi.</p>
          <p>5.2. Ushbu Shartnoma taraflari quyidagi huquqlarga ega:</p>
          <p>5.2.1. Platforma ma’muriyati quyidagi huquqlarga ega:</p>
          <p>- Foydalanuvchi tomonidan xizmat ko’rsatish uchun ulanishni o’rnatish, qabul qilish, ruxsat berish yoki Platformada belgilangan tartibda ularni bekor qilish;</p>
          <p>- pullik xizmatlar uchun to’lovlarni undirish;</p>
          <p>- o’z mablag’i va o’z ixtiyori bilan platformada Foydalanuvchilarga taklif etilayotgan xizmatlarni ilgari surish maqsadida reklama kampaniyalarini o’tkazish;</p>
          <p>- Mijozlarni qidirish va ularga Foydalanuvchi xizmatlarini taklif qilish, shuningdek ularni xizmatlar ko’rsatish shartlari bilan tanishtirish.</p>
          <p>5.2.2. Foydalanuvchi quyidagi huquqlarga ega:</p>
          <p>- belgilangan shartlarga muvofiq Platforma xizmatlarini qabul qilish;</p>
          <p>- kelishilgan shartlar asosida platformaga kirish huquqiga ega bo’lish;</p>
          <p>- zarur hollarda ma’muriyatdan tushuntirishlar va yordam olish.</p>
          <p>5.3. Ushbu Shartnoma taraflari quyidagi majburiyatlarni bajaradilar.</p>
          <p>5.3.1. Platforma ma’muriyati (operatori) quyidagi majburiyatlarni bajaradi:</p>
          <p>- ushbu Shartnoma shartlariga muvofiq xizmatlar uchun to’lovni o’z vaqtida to’lash;</p>
          <p>- ushbu Shartnomada nazarda tutilgan talablar va standartlarga qat’iy muvofiq harakat qilish, shuningdek, O’zbekiston Respublikasining xizmat ko’rsatish sohasidagi amaldagi qonunchiligiga qat’iy rioya qilish;</p>
          <p>- Axborot parametrlarini qabul qilish, tekshirish va jo’natish bo’yicha texnik talablarga, shuningdek, Platforma bilan ishlash qoidalariga qat’iy rioya qilgan holda harakat qilish;</p>
          <p>- Maxfiylik siyosatiga muvofiq foydalanuvchilarning shaxsiy ma’lumotlarining maxfiyligini ta’minlash (ommaga ochiq ma’lumotlar bundan mustasno);</p>
          <p>- «Road24» platformasining uzluksiz va maqsadli ishlashini ta’minlash va rivojlantirish;</p>
          <p>- ushbu Shartnoma shartlariga rioya qilish;</p>
          <p>- Foydalanuvchilardan ushbu Shartnoma bo’yicha o’z majburiyatlarini to’g’ri bajarishlarini talab qilish.</p>
          <p>5.3.2. Foydalanuvchi quyidagi majburiyatlarni bajaradi:</p>
          <p>- ushbu Shartnoma shartlariga rioya qilish;</p>
          <p>- aniq va to’g’ri ma’lumotlarni taqdim etish;</p>
          <p>- Sayt xizmatlaridan foydalanish jarayonida O’zbekiston Respublikasining amaldagi qonunchiligiga zid bo’lgan va uchinchi shaxslarning qonuniy manfaatlariga zarar yetkazishi mumkin bo’lgan, shuningdek, reklama, erotik, pornografik va haqoratomuz so’zlardan (ma’lumotlardan) foydalanmaslik;</p>
          <p>- uchinchi shaxslarning (har qanday) ma’lumotlarga (materiallarga), sharhlarga mualliflik huquqlari, tovar belgilari, boshqa intellektual mulk huquqlarini buzmaslik;</p>
          <p>- ko’rsatilgan pullik xizmatlar uchun to’lovlarni o’z vaqtida to’lash;</p>
          <p>- saytdan noqonuniy maqsadlarda foydalanmaslik, uni o’zgartirmaslik;</p>
          <p>- boshqa shaxslarning shaxsiy ma’lumotlari va shaxsiy hayotining maxfiyligini saqlash;</p>
          <p>- ushbu Shartnomada belgilangan tartibda Platformadan foydalanish;</p>
          <p>- Platformaning tegishli interfeyslarida Operator tomonidan e’lon qilingan Platforma foydalanuvchilari uchun xabarlarni kuzatish, shuningdek, ushbu xabarlardagi talablarga rioya qilish;</p>
          <p>- shaxsiy ma’lumotlarini va/yoki Platformaga kirishni ta’minlaydigan boshqa ma’lumotlar uchinchi shaxslarga bermaslik (keyingi o’rinlarda "Platformaga kirish uchun ma’lumotlar" deb yuritiladi);</p>
          <p>- Platformaga kirish uchun hisob ma’lumotlarini uchinchi shaxslar tomonidan noqonuniy ravishda olish va ulardan foydalanishning oldini olish bo’yicha zarur tashkiliy-texnik chora-tadbirlarni ko’rish;</p>
          <p>- Operatorga Platformaga kirish uchun Hisob ma’lumotlaridan uchinchi shaxslar tomonidan noqonuniy foydalanishga doir faktlar yoki shubhalar, shu jumladan mobil telefon raqamiga kirish yo’qolgan (yo’qolgan, o’g’irlangan yoki yo’qolgan boshqa vositalar) to’g’risida darhol xabardor qilish. </p>
          <p>- Platforma orqali noqonuniy moliyaviy operatsiyalarni, noqonuniy savdoni, jinoyatdan olingan daromadlarni legallashtirishga yoki terrorizmni moliyalashtirishga qaratilgan operatsiyalarni yoki O’zbekiston Respublikasi qonunchiligida taqiqlangan boshqa harakatlarni amalga oshirmaslik;</p>
          <p>- foydani muntazam ravishda olish yoki daromadni yashirishga qaratilgan operatsiyalarni amalga oshirish uchun Platformadan foydalanmaslik.</p>
          <p>5.4. Foydalanuvchi Platformaga aniq va to’g’ri ma’lumotlarni taqdim etishi shart. Foydalanuvchi tomonidan Sayt ma’muriyatiga ma’lumot taqdim etilmagan yoki to’liq taqdim etilmagan taqdirda, Sayt ma’muriyati Foydalanuvchining ulanishini/ro’yxatdan o’tishini bekor qilishi yoki uni Foydalanuvchi xohlaganidan boshqa shaklda ro’yxatdan o’tkazishi mumkin.</p>
          <p>5.5. Platformada boshqa foydalanuvchilarni chalg’itishga qaratilgan harakatlar, shuningdek, quyidagilar qat’iyan man etadi:</p>
          <p>- bir nechta akkauntlar yaratish / bir nechta API-larga qo’shilish, o’zini boshqa birovga o’xshatish, ayniqsa boshqa kompaniyalar, yuridik shaxslarning nomlari yoki taxalluslari bilan qayta-qayta ro’yhatdan o’tkazish;</p>
          <p>- yolg’on ma’lumotlar asosida qo’shilish, ma’lumotni buzib ko’rsatish, o’zi haqida noto’g’ri ma’lumot joylashtirish va boshqalar;</p>
          <p>- ariza blanklarida zarur bo’lmagan, keraksiz ma’lumotlarni joylashtirish;</p>
          <p>- xabar kimga qaratilganligini aniqlab bo’lmaydigan darajada ma’lumotni buzib ko’rsatadigan sharhlar qoldirish;</p>
          <p>- Sayt platformasidan foydalanishda qo’pol imlo xatolariga yo’l qo’yish;</p>
          <p>- Platformada ularning xizmatlaridan shaxsan olmagan yoki foydalanmagan shaxslar haqida sharhlar va reytinglar qoldirish.</p>
          <p>-xizmat ko’rsatishda O’zbekiston Respublikasining amaldagi qonunchiligini buzish.</p>
          <p>5.6. Platformada joylashtirilgan barcha obyektlar, shu jumladan dizayn elementlari, matnlar, grafikalar, rasmlar, videolar, skriptlar, dasturlar va boshqa obyektlar va ularning komplekslari (keyingi o’rinlarda Kontent deb yuritiladi) Sayt ma’muriyati, sayt foydalanuvchilari va boshqa huquq egalari mualliflik huquqi bilan himoyalangan. </p>
          <p>5.7. Hech qanday Kontentni ushbu Shartnomada ko’rsatilganidan hollardan tashqari va respublikaning amaldagi qonunchiligiga muvofiq ravishda to’liq yoki qisman nusxalash (qayta nashr etish), qayta ishlash, tarqatish, namoyish qilish, nashr qilish, yuklab olish, uzatish, sotish yoki sotib olish mumkin emas. O’zbekiston Respublikasi, muallifning oldindan roziligisiz, muallifning aniq ruxsati bo’lgan hollar bundan mustasnodir.</p>
          <p>5.8. Foydalanuvchi boshqa saytlar, maʼlumotlar bazalari va boshqa mualliflarning mazmuni bilan bogʻliq intellektual faoliyat natijalarini muallifning roziligisiz, Platformada yuklash yoki nashr etish huquqiga ega emas.</p>
          <p>5.9. Foydalanuvchi O’zbekiston Respublikasining amaldagi qonunchiligiga muvofiq ro’yxatdan o’tish jarayonida taqdim etilgan, shuningdek, shaxsiy kabinetida joylashtirilgan shaxsiy ma’lumotlarni qayta ishlash va saqlash, uchinchi shaxslarga taqdim etishga rozilik bergan deb hisoblanadi. Shaxsiy ma’lumotlarni qayta ishlash O’zbekiston Respublikasi qonun hujjatlariga muvofiq amalga oshiriladi.</p>
          <p>5.10. Sayt ma’muriyati Foydalanuvchining shaxsiy ma’lumotlarini qayta ishlash va saqlashi mumkin, shunda u Sayt xizmatlaridan foydalanishi va Sayt xizmatlari sifatini yaxshilashga qaratilgan tegishli ma’lumotlarni tahlil qilishi mumkin.</p>
          <p>5.11. Sayt ma’muriyati Foydalanuvchining identifikatori bilan bog’liq barcha ma’lumotlarni noqonuniy olish, o’zgartirish, oshkor qilish yoki yo’q qilishning oldini olish choralarini ko’radi. Sayt ma’muriyati foydalanuvchi ma’lumotlari bilan faqat sayt funksiyalarini sozlash va texnik xizmat ko’rsatish uchun zarur bo’lgandagina boshqalarga ishlashga ruxsat berishi mumkin.</p>
          <p>5.12. Sayt maʼmuriyati Foydalanuvchi tomonidan taqdim etilgan maʼlumotlardan, shuningdek, shaxsiy maʼlumotlardan Oʻzbekiston Respublikasi qonunchiligi talablariga rioya qilish maqsadida (shu jumladan, Foydalanuvchining qonunga xilof harakatlarining oldini olish yoki ularga chek qoʻyish maqsadida) foydalanishi mumkin. Foydalanuvchilar toʻgʻrisidagi maʼlumotlar Oʻzbekiston Respublikasi qonunchiligiga muvofiq, Shartnoma boʻyicha yoki sudning hamda qonun hujjatlarida vakolat berilgan boshqa huquqni muhofaza qiluvchi va davlat organlarining talabiga binoan boshqa shaxslarga berilishi mumkin.</p>
          <p></p>
        <p><strong>6. PULLIK XIZMATLARNING NARXI VA TO</strong><strong>’</strong><strong>LOV TARTIBI</strong></p>
          <p>6.1. Platforma jismoniy va yuridik shaxslarga pullik xizmatlar ko’rsatadi. Pullik xizmatlarning narxi va to’lov tartibi ushbu Shartnomada va/yoki Platformaning o’zida belgilangan qoidalar bilan tartibga solinadi.</p>
          <p>6.2. Xizmatlarning narxi Ma’muriyat tomonidan belgilangan tariflar bilan belgilanadi. Platformada ro’yxatdan o’tish Foydalanuvchining xizmat narxi, belgilangan to’lov qiymati va ushbu Shartnoma shartlari bilan roziligini bildiradi.</p>
          <p>6.3. Tomonlar Platformaning sug’urta kompaniyalari, banklar, Yagona interaktiv davlat xizmatlari portali va foydalanuvchi tashkilotlari o’rtasida vositachi vazifasini bajarishini qabul qiladi va rozilik beradi. Platforma Foydalanuvchi tashkilotlari, sugʻurta kompaniyalari, banklar va boshqalar tomonidan belgilangan xizmatlar narxi uchun javobgar emas.</p>
          <p>6.4. Platforma vositachi sifatida Foydalanuvchilardan belgilangan tartibda belgilangan haq oladi. Uzrli sabablarsiz toʻlovlar amalga oshirilmagan taqdirda, Maʼmuriyat Platformada Foydalanuvchilarning xizmatlarini cheklash yoki bekor qilish choralarini koʻradi.</p>
          <p>6.5. Platformadan foydalanish va u bilan muloqot qilishda xizmatni oluvchilar va tegishli kompaniyalar o’rtasida kelishmovchilik yuzaga kelsa, Ma’muriyat muammoli vaziyatga aralashmaydi yoki hal qilmaydi.</p>
          <p>6.6. Hamkor tashkilotlar va Xizmat oluvchi o’rtasidagi pul mablag’larini qaytarish bo’yicha savollar va nizolar ushbu tomonlar o’rtasidagi alohida kelishuv bilan hal qilinadi. Bunday holda, Ma’muriyat pul mablag’larini qaytarmaydi, qoplamaydi va qaytarilishi uchun javobgar emas. Xizmatlarni ko’rsatish uchun olingan to’lov qaytarilmaydi.</p>
          <p></p>
        <p><strong>7. SHAXSIY </strong><strong>MA</strong><strong>’</strong><strong>LUMOTLARNING MAXFIYLIK SIYO</strong><strong>SATI</strong></p>
          <p>7.1. Ma’lumotlar maxfiyligi siyosati Platformada ma’lumotlarning maxfiyligi munosabatlarini boshqaradi.</p>
          <p>7.2. Ma’lumotlarning maxfiyligi qoidalari ushbu Shartnomaning bir qismi bo’lgan va Shartnomaning 3.2-bandda ko’rsatilgan alohida hujjat bilan tartibga solinadi. </p>
          <p>7.3. Qoidalar Sayt ro’yxatdan o’tish va foydalanish paytida Foydalanuvchi haqida olishi mumkin bo’lgan barcha ma’lumotlarga nisbatan qo’llaniladi. Foydalanuvchi tomonidan Saytni ro’yxatdan o’tkazish va undan foydalanish ma’lumotlarning maxfiyligi siyosati va unda foydalanuvchining shaxsiy ma’lumotlarini qayta ishlash shartlari bilan so’zsiz kelishuvni anglatadi. Agar foydalanuvchi ushbu shartlarga rozi bo’lmasa, u Platformadan foydalanmasligi kerak.</p>
          <p>7.4. Tomonlar O’zbekiston Respublikasining amaldagi qonunchiligiga muvofiq, Tomonlar tomonidan tijorat siri sifatida tasniflangan va bitimni amalga oshirish jarayonida tomonlarga ma’lum bo’lgan ma’lumotlarning, shu jumladan, cheklanmagan holda, maxfiyligini saqlash majburiyatini oladilar:</p>
          <p>- to’lovchilar, to’lovlar hajmlari to’g’risidagi ma’lumotlar;</p>
          <p>- boshqaruv tizimining texnik hujjatlari;</p>
          <p>- Ma’muriyatning ish haqi miqdori va Shartnomaning moliyaviy shartlari to’g’risidagi ma’lumotlar.</p>
          <p>7.5. Tomonlar maxfiy ma’lumotlarning oshkor etilishi uchun O’zbekiston Respublikasi qonun hujjatlariga muvofiq javobgar bo’ladilar.</p>
        <p><strong>8. TOMONLARNING KAFOLATLARI VA VAKOLATLARI</strong></p>
          <p>8.1. <strong>«Road24»</strong> platformasi o’z Foydalanuvchilariga kafolatlangan sifatli xizmatlarni taqdim etadi.</p>
          <p>8.2. Ma’muriyat Xizmatlarni oluvchi va hamkor tashkilotlar o’rtasida tuzilgan oldi-sotdi bitimlari, xizmatlar ko’rsatish, ishlarni bajarish va boshqa bitimlarning tarafi emas.</p>
          <p>8.3. Har bir Tomon shu bilan boshqa Tomonga quyidagilarni bildiradi va kafolatlaydi:</p>
          <p>- u belgilangan tartibda tashkil etilgan va ro’yxatdan o’tgan O’zbekiston Respublikasi Soliq kodeksining 14 va 15-moddalari bilan xabardor etilgan bo’lib, O’zbekiston Respublikasi qonunchiligiga muvofiq qonuniy faoliyat ko’rsatayotgan yuridik shaxs bo’lib, qonun hujjatlariga muvofiq tijorat, litsenziyalanadigan faoliyatni amalga oshirish huquqiga ega. O’zbekiston Respublikasining qonun hujjatlari yaxshi obro’-e’tiborga ega, zarur imkoniyatlarga, inventarga, shuningdek, o’z holatidagi mutaxassislarga ega, Ma’muriyat va tegishli davlat organlari oldidagi o’z majburiyatlarini bajarish imkoniyatini kafolatlaydi;</p>
          <p>- ushbu Shartnomani imzolagan shaxs ushbu harakat uchun barcha zarur vakolatlarga ega;</p>
          <p>- tomonlar o’z majburiyatlari bo’yicha uchinchi shaxslar oldida javob bermaydilar;</p>
          <p>- hech bir tomon tugatilish jarayonida emas. Tomonlar uchun ushbu Shartnomaning to’g’ri bajarilishiga to’sqinlik qiladigan uchinchi shaxslarning taqiqlari, garovlari, yuklari yoki huquqlariga ega emaslar.</p>
          <p>8.4. Hech qaysi taraf, na uning direktorlari, mansabdor shaxslari yoki xodimlari sanksiya obyekti hisoblanmaydi, ularga egalik qilmaydi yoki nazorat qilinmaydi yoki boshqa ruxsat etilgan shaxs yoki yuridik shaxs nomidan harakat qilmaydi.</p>
          <p>8.5. Tomonlar tarafidan Shartnoma bo’yicha o’z majburiyatlarini bajarish uchun jalb qilingan har qanday jismoniy yoki yuridik shaxslar, shu jumladan ularning xodimlari, agentlari, maslahatchilari, pudratchilar/yetkazib beruvchilar va subpudratchilar/pudratchilar O’zbekistonning "Korrupsiyaga qarshi kurashish to’g’risida"gi qonuni talablariga muvofiq (01.03.2017 y., O’RQ-419-son):</p>
          <p>- bu mansabdor shaxs o’z xizmat vazifalarini bajarishda biror-bir harakat yoki harakatsizlikni amalga oshirishi uchun pora yoki boshqa korruptsion to’lovlarni, shu jumladan biror bir davlat mansabdor shaxsiga taklif qilmasligi, va’da qilmasligi, bermasligi, talab qilmasligi yoki olmasligi;</p>
          <p>- taraflar, ularning bevosita yoki bilvosita aktsiyadorlari (ishtirokchilari), direktorlari, mansabdor shaxslari yoki xodimlari tomonidan qasddan yoki ehtiyotsizlik oqibatida qonun buzilishiga olib kelishi mumkin bo’lgan xatti-harakatlarga yo’l qo’ymaslik;</p>
          <p>- agar Tomonlardan birortasida O’zbekiston Respublikasining korruptsiyaga qarshi qonunchiligi talablari buzilganligi (yoki mumkin bo’lgan buzilishi) to’g’risida asosli shubhalar mavjud bo’lsa, ular boshqa Tomon va uning vakillari bilan vijdonan hamkorlik qilishlari shart.</p>
          <p></p>
        <p><strong>9. TOMONLARNING MA</strong><strong>’</strong><strong>LUMOTI</strong></p>
          <p>9.1. Tomonlar ushbu Shartnoma shartlarini bajarmaganlik yoki lozim darajada bajarmaganlik uchun O’zbekiston Respublikasining amaldagi qonunchiligiga muvofiq javobgar bo’ladilar.</p>
          <p>9.2. Foydalanuvchi ushbu Shartnoma bo’yicha majburiyatlarni bajarmaslik yoki lozim darajada bajarmaslik natijasida yuzaga keladigan har qanday zarar yoki boshqa noxush oqibatlar uchun javobgardir.</p>
          <p>9.3. Ishonchsiz havolalardan foydalanadigan qurilmaga shaxsiy ma’lumotlarini kiritishda yoki zararli dasturlar va ilovalar xavfi ostida bo’lganida foydalanuvchi yakka tartibda javobgar bo’ladi.</p>
          <p>9.4. Sayt, agar nomuvofiqlik yoki Shartnoma shartlariga rioya qilmaslik Foydalanuvchi tomonidan sodi etilgan  bo’lsa, Shartnoma shartlariga rioya qilmaslik uchun javobgar emas,.</p>
          <p>9.5. Foydalanuvchi ushbu Shartnoma shartlari, shuningdek, faollashtirish vaqtida ularning takliflari bilan o’z vaqtida tanishmaganligi va (yoki) o’z vaqtida tanishmaganligi sababli Foydalanuvchiga yetkazilgan zarar uchun Sayt javobgar emas.</p>
          <p>9.6. Agar bunday nomuvofiqlik Saytga bog’liq bo’lmagan sabablarga ko’ra yuzaga kelgan bo’lsa, Sayt Shartnoma shartlariga rioya qilmaslik uchun javobgar emas. Platformani nazorat qilish doirasi Saytning o’z faoliyati bilan cheklangan va uchinchi shaxslarning xatolari yoki davlat organlari yoki sud organlari tomonidan qo’yilgan taqiqlar va cheklovlar tufayli ishdagi uzilishlar natijasida yuzaga kelgan nosozliklar uchun javobgarlikni o’z ichiga olmaydi.</p>
          <p>9.7. Tomonlar ushbu Shartnoma bo’yicha majburiyatlarni bajarmaganlik yoki lozim darajada bajarmaganlik uchun javobgarlikdan ozod qilinadilar, agar bunda bunday bajarmaslik favqulodda vaziyatlar natijasida yuzaga kelgan fors-major holatlarining natijasi bo’lsa, Tomon oldindan ko’ra olmagan yoki shartnoma tuzilgandan keyin oldini olish uchun oqilona choralar ko’rgan bo’lsa.</p>
          <p>9.8. Operator Shartnoma bo’yicha o’z majburiyatlarini to’liq yoki qisman bajarmaganlik uchun javobgarlikdan to’liq ozod qilinadi, agar bajarilmasligi yoki lozim darajada bajarilmasligi texnogen xususiyatdagi fors-major holatlari, xususan: avariya (ishdagi uzulish) natijasida yuzaga kelgan bo’lsa. Platformaning ishlashi uchun foydalaniladigan elektr yoki kompyuter tarmoqlari yoki boshqa elektr aloqa tizimlari, shuningdek, uchinchi shaxslarning Platforma faoliyatini buzishga qaratilgan qasddan noqonuniy harakatlari natijasida yuzaga kelgan bo’lsa.</p>
          <p>9.9. Yo’qolgan, o’g’irlangan yoki boshqa hollarda foydalanuvchi Platforma ulangan mobil (abonent) telefon raqamiga kirish imkoniga ega bo’lmasa, Foydalanuvchi uchinchi shaxslarning ushbu platformadan foydalanish bilan bog’liq har qanday harakatlari uchun barcha xavf va javobgarlikni o’z zimmasiga oladi. </p>
          <p>9.10. Operator foydalanuvchining texnik platformalari va transport tarmoqlari yoki aloqa tarmoqlarining ishlashidagi kechikishlar va uzilishlar uchun javobgar emas, ularning paydo bo’lishi Operatorning aybi bilan bo’lmaydi.</p>
          <p>9.11. Operator Foydalanuvchi tomonidan Platforma va/yoki boshqa shaxsiy ma’lumotlarga kirish uchun o’z Hisob ma’lumotlarini oshkor qilganligi sababli Foydalanuvchiga yetkazilgan har qanday zarar uchun javobgar emas.</p>
          <p>9.12. Operator Hamkor tashkilotlar tomonidan Foydalanuvchiga taqdim etilayotgan xizmatlarning sifati, shuningdek, Foydalanuvchilar tomonidan sotib olingan xizmatlarning foydalanuvchilarga taqdim etilishi bilan bog’liq jarayonda va foydalanuvchilar oldidagi majburiyatlari uchun javobgar emas. </p>
          <p>9.13. Foydalanuvchi o’zi tomonidan Platformada amalga oshirilgan barcha operatsiyalar uchun javobgardir.</p>
          <p>9.14. Operator boshqa tashkilotlar, foydalanuvchilar tomonidan taqdim etilgan aloqa liniyalarining sifati va unga tegishli bo’lmagan boshqa foydalanuvchilarga tegishli bo’lgan boshqa, shu jumladan tranzit, ma’lumotlarni uzatish tarmoqlari tugunlarining jihozlarining ishlashi uchun javobgar emas.</p>
          <p>9.15. Foydalanuvchi Platforma ulangan Foydalanuvchi nomidan sodir etilgan uchinchi shaxslarning har qanday harakatlari, shuningdek, uning qurilmasida o’rnatilgan ilovalar va dasturlardan foydalanish yoki Platformaga kirish uchun uning Hisob ma’lumotlaridan foydalanish uchun tavakkalchilik va javobgarlikni o’z zimmasiga oladi.</p>
          <p></p>
        <p><strong>10. SHARTNOMANI TUGATISH</strong></p>
          <p>10.1. Shartnoma quyidagi hollarda bekor qilinishi mumkin:</p>
          <p>- Tomonlar kelishuvi bo’yicha;</p>
          <p>- Shartnomada nazarda tutilgan kelishmovchiliklarni hal qilish tartibini hisobga olgan holda, boshqa Tomon tarafidan Bitimning bir yoki bir nechta shartlari takroran buzilgan taqdirda bir tomonlama;</p>
          <p>- Tomonlardan birortasining tashabbusi bilan, Shartnomani bekor qilishning kutilayotgan sanasidan 30 (o’ttiz) kalendar kunidan kechiktirmay boshqa Tomonni xabardor qilish sharti bilan. Xabarnoma qog’ozda yozma ravishda amalga oshirilishi va Shartnomani bekor qilish sababini ko’rsatishi kerak.</p>
          <p>10.2. Ma’muriyat, agar Foydalanuvchi ushbu Shartnoma shartlarini buzsa, Platformadagi Foydalanuvchi akkauntini o’chirish va Shartnomani bekor qilish huquqini o’zida saqlab qoladi.</p>
          <p>10.3. Ushbu Shartnoma qonun hujjatlarida nazarda tutilgan boshqa asoslarda ham bekor qilinishi mumkin.</p>
          <p>10.4. Foydalanuvchi tomonidan shaxsiy kabinet o’chirilganda shartnoma bekor qilingan hisoblanadi. Shaxsiy kabinet o’chirishdan oldin, tomonlar Shartnomani bekor qilishdan oldin paydo bo’lgan majburiyatlarni bajarishlari kerak.</p>
          <p><strong>11. NIZOLARNI HAL QILISH TARTIBI</strong></p>
          <p>11.1. Ushbu Shartnomadan kelib chiqadigan yoki u bilan bog’liq har qanday nizo tinch yo’l bilan - muzokaralar yo’li bilan yoki foydalanuvchining yozma iltimosiga binoan murojaat qilish tartibida hal qilinadi. Murojaatni ko’rib chiqish muddatlari va uning mazmuniga qo’yiladigan talablar ushbu Shartnoma va O’zbekiston Respublikasining amaldagi qonunchiligi bilan belgilanadi.</p>
          <p>11.2. Agar nizo da’vo tartibida hal etilmasa, u O’zbekiston Respublikasining amaldagi qonunchiligida belgilangan tartibda tegishli sud tomonidan ko’rib chiqilishi mumkin.</p>
          <p></p>
        <p><strong>12. BOSHQA SHARTLAR</strong></p>
          <p>12.1. Platforma tegishli xizmatlarni birgalikda ko’rsatuvchi tashkilotlarning ma’lumotlar bazalari bilan integratsiyalangan va foydalanuvchi ma’lumotlari ushbu tashkilotlar tomonidan qabul qilinadi. Foydalanuvchilar haqidagi ma’lumotlar ham ushbu tashkilotlar tomonidan Platformaga taqdim etiladi.</p>
          <p>12.2. Foydalanuvchi va uchinchi shaxslar tomonidan platforma tarkibidan notijorat maqsadlarda foydalanishga barcha mualliflik huquqlari, muallifning ismi va Kontent o’zgarishsiz qolishi sharti bilan ruxsat etiladi.</p>
          <p>12.3. Uchinchi tomon saytlari va kontenti:</p>
          <p>12.3.1. Saytda Internetdagi boshqa saytlarda (uchinchi shaxslarga tegishli saytlar) maqolalar, fotosuratlar, illyustratsiyalar, grafikalar, ma’lumotlar, ilovalar, dasturlar va O’zbekiston Respublikasi qonunchiligi bilan himoyalangan intellektual faoliyat natijasi bo’lgan boshqa kontentga havolalar bo’lishi mumkin. ).</p>
          <p>12.3.2. Uchinchi shaxslarga tegishli deb ko’rsatilgan kontent Sayt ma’muriyati tomonidan hech qanday talablarga (ishonchlilik, to’liqlik, xolislik) muvofiqligi tekshirilmaydi. Sayt ma’muriyati Platformada e’lon qilingan uchinchi shaxslar to’g’risidagi foydalanuvchi tomonidan ruxsat etilgan yoki uchinchi shaxs kontentidan olingan har qanday ma’lumotlar, shuningdek uchinchi shaxslar tomonidan bildirilgan yoki ularning Kontentida ma’lumotlar uchun javobgar emas.</p>
          <p>12.3.3. Platformada joylashtirilgan har qanday boshqa Platformalar to’g’risidagi ma’lumotlar, mahsulot, xizmat haqidagi tijorat yoki notijorat xarakterdagi har qanday ma’lumotlar ularning Sayt ma’muriyati tomonidan tavsiya etilgan mazmunni bildirmaydi.</p>
          <p>12.4. Ushbu Taklifni qabul qilib, Foydalanuvchi Shartnomaning barcha shartlariga so’zsiz roziligini tasdiqlaydi va ularga rioya qilish majburiyatini oladi.</p>
          <p>12.5. Saytning tegishli platformalari va bo’limlarida ulardan foydalanish qoidalari, ko’rsatmalari, ma’lumotlari va tavsiyalari ushbu Shartnomaning ajralmas qismi hisoblanadi. Ushbu Platformalar va Saytning bo’limlari va funktsiyalaridan foydalangan holda, Foydalanuvchi ulardagi qoidalar, ko’rsatmalar, ma’lumotlar va tavsiyalarga rozi bo’ladi.</p>
          <p>12.6. Ushbu Shartnomaning amal qilish muddati cheklanmagan. Agar tomonlardan hech biri shartnomaviy munosabatlarni bekor qilish istagini bildirmasa, shartnoma keyingi yilga uzaytirilgan hisoblanadi.</p>
          <p>12.7. Ushbu Ommaviy oferta rasmiy hujjat bo’lib, unga ilova qilingan hujjatlar Ommaviy ofertaning ajralmas qismi hisoblanadi.</p>
          <p>12.8. Tomonlar shaxsiy ma’lumotlari o’zgargan taqdirda bir-birlarini darhol xabardor qilishlari va Platformaga o’zgartirishlar kiritishlari shart.</p>
          <p>12.9. Shartnomada ko’rsatilmagan boshqa munosabatlar ushbu Bitim maqsadlariga muvofiq O’zbekiston Respublikasining amaldagi qonunchiligi bilan tartibga solinadi.</p>
          <p></p>
          <p></p>
      </div>
    </>
  );
}

export default PrivacyPolicy;
